import React from "react";
import { Link } from "gatsby";
import cx from "classnames";
import { useLocation } from "@reach/router";
import styled from "styled-components";

const NavLink = styled(Link)``;
interface NavItemProps {
  to: string;
}

const NavItem: React.FunctionComponent<NavItemProps> = ({ to, children }) => {
  const { pathname,hash,href } = useLocation();
  const active = `${pathname}${hash}` === to;
  return (
    <NavLink
      to={to}
      className={cx(
        "px-3 py-2 hover:bg-gray-200 rounded-md text-base font-bold ml-2 text-center transform transition duration-500 ease-in-out hover:scale-110",
        { "bg-blue-100 text-blue-700 hover:bg-blue-100": active }
      )}
    >
      {children}
    </NavLink>
  );
};

interface NavProps {
  className?: string;
}

const Nav: React.FunctionComponent<NavProps> = ({ className }) => {
  return (
    <nav className={cx("flex items-baseline", className)}>
      <NavItem to="/">Home</NavItem>
      <NavItem to="/#about">About us</NavItem>
      <NavItem to="/gallery">Gallery</NavItem>
      <NavItem to="/#contact">Contact</NavItem>
    </nav>
  );
};

export default Nav;
