import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import LogoImage from "../images/logo.png";
import Input from "./commons/Input";
import Nav from "./Nav";

const Header = ({ siteTitle }) => (
  <header className="relative z-10 border-b border-gray-200 bg-white flex flex-wrap items-center md:justify-between px-4 py-4 sm:px-6 lg:px-8 xl:px-16">
    <Link to="/" className="w-full md:w-auto mb-5 md:mb-0">
      <img src={LogoImage} className="w-40 md:w-56" />
    </Link>
    <div className="w-full md:w-auto md:flex-grow flex justify-center md:justify-end">
      {/* <Input className="md:w-auto" placeholder="search"/> */}
      <Nav className="w-full md:w-auto md:ml-5 justify-center" />
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
